// Add your JS customizations here
(function ($) {

    $(document).ready(function(){

        // Add your custom JS here.
        if ( $('.homepage-slider').length > 0 ) {
            /* TinySlider */
            var slider = tns({
                container: '.homepage-slider',
                items: 1,
                mouseDrag: true,
                autoplay: true,
                speed: 2500,
                autoplayTimeout: 10000,
                autoplayButtonOutput: false,
                nav: true,
                controlsContainer: '.controls',
            });
        }

        if ( $('.image-slider').length > 0 ) {
            /* TinySlider */
            var slider = tns({
                container: '.image-slider',
                items: 1,
                mouseDrag: true,
                autoplay: true,
                speed: 2500,
                autoplayTimeout: 10000,
                autoplayButtonOutput: false,
                nav: false,
                controlsContainer: '.controls-images',
            });
        }

        if ( $('.case-studies-slider').length > 0 ) {
            /* TinySlider */
            var slider = tns({
                container: '.case-studies-slider',
                items: 1,
                mouseDrag: true,
                autoplay: true,
                speed: 2500,
                autoplayTimeout: 10000,
                autoplayButtonOutput: false,
                nav: true,
                controlsContainer: '.controls-case-studies'
            });
        }

        if ( $('.reviews-slider').length > 0 ) {
            /* TinySlider */
            var slider = tns({
                container: '.reviews-slider',
                items: 3,
                slideBy: 1,
                mouseDrag: true,
                autoplay: true,
                speed: 2500,
                autoplayTimeout: 10000,
                autoplayButtonOutput: false,
                nav: false,
                
                controlsContainer: '.controls-reviews',
                responsive: {
                    0: {
                        items: 1,
                        gutter: 20
                    },
                    500: {
                        items: 1,
                        gutter: 20
                    },
                    767: {
                        items: 2,
                        gutter: 20
                    },
                    1000: {
                        items: 2,
                        gutter: 50
                    },
                    1400: {
                        items: 3,
                        gutter: 100
                    }
                }
            });
        }

    });

})(jQuery);
